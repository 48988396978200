<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <form @submit.prevent="Update" @keydown="form.onKeydown($event)">
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="home_category_title"
              >Trendy Category Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'home_category_title'
                ),
              }"
              name="home_category_title"
              type="text"
              v-model="form.home_category_title"
              id="home_category_title"
              placeholder="Enter Trendy Category Section Title"
            />
            <has-error :form="form" field="home_category_title"></has-error>
          </div>
          <div class="form-group">
            <label for="home_category_description"
              >Trendy Category Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'home_category_description'
                ),
              }"
              name="home_category_description"
              type="textarea"
              :rows="3"
              v-model="form.home_category_description"
              id="home_category_description"
              placeholder="Enter Trendy Category Section Description"
            />
            <has-error
              :form="form"
              field="home_category_description"
            ></has-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="home_why_ahead_everyone_title"
              >Why Are Ae Ahead of Everyone Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'home_why_ahead_everyone_title'
                ),
              }"
              name="home_why_ahead_everyone_title"
              type="text"
              v-model="form.home_why_ahead_everyone_title"
              id="home_why_ahead_everyone_title"
              placeholder="Enter Why Are Ae Ahead of Everyone Section Title"
            />
            <has-error
              :form="form"
              field="home_why_ahead_everyone_title"
            ></has-error>
          </div>
          <div class="form-group">
            <label for="home_why_ahead_everyone_description"
              >Why Are Ae Ahead of Everyone Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'home_why_ahead_everyone_description'
                ),
              }"
              name="home_why_ahead_everyone_description"
              type="textarea"
              :rows="3"
              v-model="form.home_why_ahead_everyone_description"
              id="home_why_ahead_everyone_description"
              placeholder="Enter Why Are Ae Ahead of Everyone Section Description"
            />
            <has-error
              :form="form"
              field="home_why_ahead_everyone_description"
            ></has-error>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="ahead_id"
              >Select Why Are Ae Ahead of Everyone Options</label
            >
            <Select
              v-model="form.ahead_id"
              filterable
              name="ahead_id"
              id="ahead_id"
              placeholder="Select Why Are Ae Ahead of Everyone Options"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 1"
                >{{ option.description }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="choose_your_category_title"
              >Choose Your Category Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'choose_your_category_title'
                ),
              }"
              name="choose_your_category_title"
              type="text"
              v-model="form.choose_your_category_title"
              id="choose_your_category_title"
              placeholder="Enter Choose Your Category Section Title"
            />
            <has-error
              :form="form"
              field="choose_your_category_title"
            ></has-error>
          </div>
          <div class="form-group">
            <label for="choose_your_category_description"
              >Choose Your Category Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'choose_your_category_description'
                ),
              }"
              name="choose_your_category_description"
              type="textarea"
              :rows="3"
              v-model="form.choose_your_category_description"
              id="choose_your_category_description"
              placeholder="Enter Choose Your Category Section Description"
            />
            <has-error
              :form="form"
              field="choose_your_category_description"
            ></has-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="course_starting_title"
              >How To Start Course Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'course_starting_title'
                ),
              }"
              name="course_starting_title"
              type="text"
              v-model="form.course_starting_title"
              id="course_starting_title"
              placeholder="Enter How To Start Course Section Title"
            />
            <has-error :form="form" field="course_starting_title"></has-error>
          </div>
          <div class="form-group">
            <label for="course_starting_description"
              >How To Start Course Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'course_starting_description'
                ),
              }"
              name="course_starting_description"
              type="textarea"
              :rows="3"
              v-model="form.course_starting_description"
              id="course_starting_description"
              placeholder="Enter How To Start Course Section Description"
            />
            <has-error
              :form="form"
              field="course_starting_description"
            ></has-error>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="ahead_id">Select How To Start Course Options</label>
            <Select
              v-model="form.start_course_id"
              filterable
              name="start_course_id"
              id="start_course_id"
              placeholder="Select How To Start Course Options"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 2"
                >{{ option.name }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="student_feedback_title"
              >Student Feedback Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'student_feedback_title'
                ),
              }"
              name="student_feedback_title"
              type="text"
              v-model="form.student_feedback_title"
              id="student_feedback_title"
              placeholder="Enter Student Feedback Section Title"
            />
            <has-error :form="form" field="student_feedback_title"></has-error>
          </div>
          <div class="form-group">
            <label for="student_feedback_description"
              >Student Feedback Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'student_feedback_description'
                ),
              }"
              name="student_feedback_description"
              type="textarea"
              :rows="3"
              v-model="form.student_feedback_description"
              id="student_feedback_description"
              placeholder="Enter Student Feedback Section Description"
            />
            <has-error
              :form="form"
              field="student_feedback_description"
            ></has-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="why_buy_course_title"
              >Why Buy Course Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'why_buy_course_title'
                ),
              }"
              name="why_buy_course_title"
              type="text"
              v-model="form.why_buy_course_title"
              id="why_buy_course_title"
              placeholder="Enter Why Buy Course Section Title"
            />
            <has-error :form="form" field="why_buy_course_title"></has-error>
          </div>
          <div class="form-group">
            <label for="why_buy_course_description"
              >Why Buy Course Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'why_buy_course_description'
                ),
              }"
              name="why_buy_course_description"
              type="textarea"
              :rows="3"
              v-model="form.why_buy_course_description"
              id="why_buy_course_description"
              placeholder="Enter Why Buy Course Section Description"
            />
            <has-error
              :form="form"
              field="why_buy_course_description"
            ></has-error>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="buy_id">Select Why Buy Course Options</label>
            <Select
              v-model="form.buy_id"
              filterable
              name="buy_id"
              id="buy_id"
              placeholder="Select Why Buy Course Options"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 3"
                >{{ option.name }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="partner_title">Partner Section Title</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has('partner_title'),
              }"
              name="partner_title"
              type="text"
              v-model="form.partner_title"
              id="partner_title"
              placeholder="Enter Partner Section Title"
            />
            <has-error :form="form" field="partner_title"></has-error>
          </div>
          <div class="form-group">
            <label for="partner_description">Partner Section Description</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'partner_description'
                ),
              }"
              name="partner_description"
              type="textarea"
              :rows="3"
              v-model="form.partner_description"
              id="partner_description"
              placeholder="Enter Partner Section Description"
            />
            <has-error :form="form" field="partner_description"></has-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="meta_title">Meta Title</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has('meta_title'),
              }"
              name="meta_title"
              type="text"
              v-model="form.meta_title"
              id="meta_title"
              placeholder="Enter Meta Title"
            />
            <has-error :form="form" field="meta_title"></has-error>
          </div>
          <div class="form-group">
            <label for="meta_description">Meta Description</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has('meta_description'),
              }"
              name="meta_description"
              type="textarea"
              :rows="3"
              v-model="form.meta_description"
              id="meta_description"
              placeholder="Enter Meta Description"
            />
            <has-error :form="form" field="meta_description"></has-error>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <b-button type="submit" variant="primary" :disabled="form.busy">
          UPDATE
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";
export default {
  data() {
    return {
      form: new Form({
        home_category_title: null,
        home_category_description: null,
        home_why_ahead_everyone_title: null,
        home_why_ahead_everyone_description: null,
        choose_your_category_title: null,
        choose_your_category_description: null,
        course_starting_title: null,
        course_starting_description: null,
        student_feedback_title: null,
        student_feedback_description: null,
        why_buy_course_title: null,
        why_buy_course_description: null,
        partner_title: null,
        partner_description: null,
        meta_title: null,
        meta_description: null,
        options: [],
        ahead_id: [],
        start_course_id: [],
        buy_id: [],
      }),
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getHomePageOptions");
  },
  methods: {
    async getResults() {
      const page = await this.callApi("get", "/app/cms/home/page");
      if (page.status == 200) {
        this.form.home_category_title = page.data.page.home_category_title;
        this.form.home_category_description =
          page.data.page.home_category_description;
        this.form.home_why_ahead_everyone_title =
          page.data.page.home_why_ahead_everyone_title;
        this.form.home_why_ahead_everyone_description =
          page.data.page.home_why_ahead_everyone_description;
        this.form.choose_your_category_title =
          page.data.page.choose_your_category_title;
        this.form.choose_your_category_description =
          page.data.page.choose_your_category_description;
        this.form.course_starting_title = page.data.page.course_starting_title;
        this.form.course_starting_description =
          page.data.page.course_starting_description;
        this.form.student_feedback_title =
          page.data.page.student_feedback_title;
        this.form.student_feedback_description =
          page.data.page.student_feedback_description;
        this.form.why_buy_course_title = page.data.page.why_buy_course_title;
        this.form.why_buy_course_description =
          page.data.page.why_buy_course_description;
        this.form.partner_title = page.data.page.partner_title;
        this.form.partner_description = page.data.page.partner_description;
        this.form.meta_title = page.data.page.meta_title;
        this.form.meta_description = page.data.page.meta_description;
        this.form.ahead_id = page.data.options;
        this.form.start_course_id = page.data.options;
        this.form.buy_id = page.data.options;
      }
    },
    Update() {
      this.optionManage();
      this.form
        .post("/app/cms/home/page")
        .then(({ data }) => {
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },

    optionManage() {
      this.form.option_id = [];
      this.form.ahead_id.forEach((data) => {
        this.form.options.push(data);
      });
      this.form.start_course_id.forEach((data) => {
        this.form.options.push(data);
      });
      this.form.buy_id.forEach((data) => {
        this.form.options.push(data);
      });
    },
  },
  components: {
    BButton,
    HasError,
    AlertError,
  },
  computed: {
    options() {
      return this.$store.getters.getHomePageOptions;
    },
  },
};
</script>

<style></style>
