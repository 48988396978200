<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-card>
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row">
            <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-12">
                  <!-- email -->
                  <b-form-group label="Title" label-for="title">
                    <validation-provider
                      #default="{ errors }"
                      name="title"
                      rules="required"
                    >
                      <b-form-input
                        id="title"
                        v-model="form.title"
                        :state="errors.length > 0 ? false : null"
                        name="title"
                        placeholder="Enter Your Super Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <!-- email -->
                  <b-form-group
                    label="Sort Description"
                    label-for="short_description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="short_description"
                      rules="required"
                    >
                      <b-form-input
                        id="short_description"
                        v-model="form.short_description"
                        :state="errors.length > 0 ? false : null"
                        name="title"
                        placeholder="Enter Your Sort Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group label="Button link" label-for="btn_link">
                        <validation-provider
                          #default="{ errors }"
                          name="btn_link"
                          rules="required"
                        >
                          <b-form-input
                            id="btn_name"
                            v-model="form.btn_link"
                            :state="errors.length > 0 ? false : null"
                            name="btn_name"
                            placeholder="Enter Your Button Link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <b-form-group label="Button Name" label-for="btn_name">
                    <validation-provider
                      #default="{ errors }"
                      name="btn_name"
                      rules="required"
                    >
                      <b-form-input
                        id="btn_name"
                        v-model="form.btn_name"
                        :state="errors.length > 0 ? false : null"
                        name="btn_name"
                        placeholder="Enter Your Button Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="border rounded p-2 mb-2">
                <h4 class="mb-1">Banner Image</h4>
                <b-media no-body class="align-items-center">
                  <b-media-aside>
                    <b-img
                      :src="image"
                      height="174"
                      width="212"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-form-group label="Banner  Image" label-for="image">
                      <b-form-file
                        id="image"
                        accept=".jpg, .png, .gif"
                        @change="loadImage($event)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
          <!-- submit buttons -->
          <b-button type="submit" variant="primary" @click="validationForm">
            Update
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      form: {
        title: "",
        short_description: "",
        btn_name: "",
        btn_link: "",
        image: null,
      },
      image: this.$store.state.base_url + "images/cms/home/banner/default.png",
      required,
      email,
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      const banner = await this.callApi("get", "/app/cms/home/banner");
      if (banner.status == 200) {
        this.form.title = banner.data.banner.title;
        this.form.short_description = banner.data.banner.short_description;
        this.form.btn_name = banner.data.banner.btn_name;
        this.form.btn_link = banner.data.banner.btn_link;
        this.image =
          this.$store.state.base_url +
          (banner.data.banner.image || "images/cms/home/banner/default.png");
      }
    },

    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.image) {
        fd.append("image", this.form.image, this.form.image.name);
      }
      fd.append("title", this.form.title);
      fd.append("short_description", this.form.short_description);
      fd.append("btn_name", this.form.btn_name);
      fd.append("btn_link", this.form.btn_link);
      fd.append("image", this.form.image);

      axios.post("/app/cms/home/banner", fd).then((res) => {
        this.getBanner();
        this.s(res.data.message);
      });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
